<template>
    <div class="subaccount">
        <div class="custom-select" :class="isSelect ? 'active' : ''">
            <p @click="isSelect = !isSelect">
                {{ currentSoftware }}<span></span>
            </p>
            <div class="select-options" v-show="isSelect">
                <p
                    v-for="(s, i) in allSoftware"
                    :key="i"
                    :class="currentSoftware == s ? 'active' : ''"
                    @click="chooseProduct(s)"
                >
                    <img
                        loading="lazy"
                        class="img40"
                        src="@/assets/img/personal/product-logo.png"
                        alt="product logo"
                    />
                    <span>{{ s }}</span>
                </p>
            </div>
        </div>
        <el-carousel
            :autoplay="false"
            arrow="always"
            :indicator-position="'none'"
        >
            <el-carousel-item v-for="(item, i) in productsInfo" :key="i">
                <img
                    loading="lazy"
                    class="img80"
                    :src="item.logo"
                    alt="product logo"
                />
                <div>
                    <p class="small-title">{{ item.name }}</p>
                    <p>{{ item.detail }}</p>
                </div>
            </el-carousel-item>
        </el-carousel>
        <div class="bottom-container">
            <div class="invite">
                <img
                    loading="lazy"
                    class="img40"
                    src="@/assets/img/personal/invite.png"
                    alt="invite"
                />
                <div>
                    <p class="small-title">{{ $t("subaccount.invite") }}</p>
                    <input type="text" v-model="inviteEmail" />
                    <p class="p-btn usable" @click="sendInvite">
                        {{ $t("subaccount.send_invite") }}
                    </p>
                </div>
            </div>
            <p class="cut-off-line"></p>
            <div class="manage">
                <p class="small-title">{{ $t("subaccount.manage_title") }}</p>
                <div class="card" v-for="(m, i) in menbers" :key="i">
                    <img
                        loading="lazy"
                        class="img40"
                        :src="m.logo"
                        alt="profile"
                    />
                    <div>
                        <p>{{ m.name }}</p>
                        <p>{{ m.email }}</p>
                        <p class="mt10">{{ m.status }}</p>
                    </div>
                    <p class="p-btn" @click="removeMenber(m.name, i)">
                        {{ $t("subaccount.remove") }}
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "subaccountFun",
    data() {
        return {
            allSoftware: [
                this.$t("subaccount.all_product"),
                // "Audible Converter",
                // "Apple Music Converter",
                "Screen Recorder",
                "Video Converter",
                // "Video Converter ",
            ],
            currentSoftware: this.$t("subaccount.all_product"),
            productsInfo: [
                {
                    logo: require("@/assets/img/personal/product-logo.png"),
                    name: "Video Converter ",
                    detail: "One Year License for 1 Macs",
                },
                {
                    logo: require("@/assets/img/personal/product-logo.png"),
                    name: "Video Converter ",
                    detail: "One Year License for 2 Macs",
                },
                {
                    logo: require("@/assets/img/personal/product-logo.png"),
                    name: "Video Converter ",
                    detail: "One Year License for 3 Macs",
                },
                {
                    logo: require("@/assets/img/personal/product-logo.png"),
                    name: "Video Converter ",
                    detail: "One Year License for 5 Macs",
                },
            ],
            inviteEmail: "",
            menbers: [
                {
                    logo: require("@/assets/img/personal/profile-orange.png"),
                    name: "用户名",
                    email: "zoenzo@gmail.com",
                    status: "Active",
                },
                {
                    logo: require("@/assets/img/personal/profile-orange.png"),
                    name: "用户名",
                    email: "zoenzo@gmail.com",
                    status: "Active",
                },
                {
                    logo: require("@/assets/img/personal/profile-orange.png"),
                    name: "用户名",
                    email: "zoenzo@gmail.com",
                    status: "Active",
                },
            ],
            isSelect: false,
        };
    },
    computed: {},
    mounted() {
    },
    methods: {
        sendInvite() {
            this.$parent.isSuccess = true;
            this.$parent.popupText = this.$t("subaccount.send_success");
        },
        removeMenber(name) {
            this.$parent.isWarning = true;
            this.$parent.popupText = this.$t("subaccount.remove_user", {
                name,
            });
            this.$parent.customConfirm = {
                text: this.$t("subaccount.remove"),
                type: "red",
            };
        },
        handleConfirm() {
            this.$parent.isSuccess = true;
            this.$parent.isWarning = false;
        },
        chooseProduct(s) {
            this.currentSoftware = s;
            this.isSelect = false;
        },
    },
};
</script>

