<template>
    <div class="services"></div>
</template>

<script>
export default {
    name: "servicesComponent",
    data() {
        return {};
    },
    computed: {},
    mounted() {
    },
    methods: {},
};
</script>

